<template>
  <div class="content">
    <a-modal v-model="open" :title="$t('学习模块.新的虚拟研究')" @ok="handleOk" :closable="false" class="modalClass">
      <a-row>
        <a-input v-model="studyName" :placeholder="$t('学习模块.学习名称')" clearable/>
      </a-row>
      <!--<a-row style="margin-top: 16px">-->
        <!--<a-input v-model="form.sensorsId" autofocus placeholder="Selected Sensors" />-->
      <!--</a-row>-->
      <template slot="footer">
        <a-button class="cancel" @click="cancel">{{$t('学习模块.取消')}}</a-button>
        <a-button class="save" @click="add" :disabled="studyName == ''">{{$t('学习模块.保存')}}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {addStudies} from "@/api/studies/studies"
export default {
  data() {
    return {
      open: false,
      form: {},
      studyName:''
    }
  },
  methods: {
    handleOk() {},
    openModal() {
      this.open = true
    },
    add(){
         addStudies({studyName : this.studyName}).then(res =>{
                // console.log('res', res)
                this.open = false
                this.$message.success('新增成功!');
                this.$emit('refresh')
         })
    },
    cancel(){
        this.open = false
    }
  },
}
</script>

<style scoped lang="less">
.content {
}

.cancel {
  border: none !important;
  color: #7f62ad;
  font-weight: 500;
  font-size: 16px;
}

.save{
      background-color: #7f62ad;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      border-radius: 4px;
}

.disable{
    background-color: #7f62ad !important;
    border-color: #7f62ad !important;
}
</style>