<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="receipt"
            class="svg-inline--fa fa-receipt fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            width="32px"
            height="32px"
            style="margin-right: 5px"
          >
            <path
              fill="currentColor"
              d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"
            ></path>
          </svg>
          {{ $t('学习模块.学习标题') }}
        </div>
        <div class="top-middle">{{ $t('学习模块.学习描述') }}</div>
        <!-- <div style="margin-top:16px">
               <a-input v-model="value" placeholder="enter min. 4-digit serial number" />
        </div> -->
        <div>
          <a-button class="btn" @click="openModal"
            >{{ $t('学习模块.新的虚拟研究') }}
            <!-- <a-icon type="plus" style="font-size: 20px; color: rgb(127, 98, 173); margin-top: 10px" /> -->
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle-plus"
              class="svg-inline--fa fa-circle-plus fa-fw"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="20px"
              height="16px"
              style="margin-left:5px;margin-top:8px"
            >
              <path
                fill="currentColor"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
              ></path>
            </svg>
          </a-button>
        </div>
      </a-card>
    </div>
    <div style="margin-top: 16px">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <Table ref="Table" @handleRowClick="handlerowclick" />
        </div>
      </a-card>
    </div>

    <!-- 弹出框 -->
    <Modal ref="ModalRefs" @refresh="$refs.Table.getList()" />
  </div>
</template>

<script>
import Table from './modules/table.vue'
import Modal from './modules/Modal.vue'
export default {
  data() {
    return {}
  },
  components: {
    Table,
    Modal,
  },
  methods: {
    openModal() {
      this.$refs.ModalRefs.openModal()
    },
    handlerowclick() {
      console.log('点击行')
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    //    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    // width: 126px;
    height: 38px;
    line-height: 38px;
   
    border: none !important;
    vertical-align:baseline !important;

  }
}
</style>